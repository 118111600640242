import React, { useEffect, useRef } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import { SmallerBlueArrow } from '../components/layout/arrows';
import Pagination from '../components/layout/pagination';
import Title from '../components/layout/title';

const ListingTemplate = ({ data, pageContext }) => {
  const paginationRef = useRef();
  const opengraph = getImage(data?.opengraph?.childImageSharp) || null;
  const { title, subtitle, description, currentPage, numPages, prefix, featuredItem } = pageContext;
  useEffect(() => {
    if (typeof window !== 'undefined' && currentPage > 1) {
      window?.scrollTo({
        behavior: 'smooth',
        top: paginationRef?.current?.getBoundingClientRect().top + window?.scrollY,
      });
    }
  }, []);

  return (
    <Layout>
      <SEO
        title={title || ''}
        description={description || ''}
        pathname={typeof window !== 'undefined' && location ? location.pathname : ''}
        image={data?.opengraph?.childImageSharp.resize || null}
      />
      <div className="bg-white overflow-x-hidden">
        <div className="relative max-w-7xl mx-auto px-5 xl:px-8">
          <section>
            <Title title={title || ''} border={false}>
              <p
                className="text-left text-sm font-light mb-4 text-gray-700 w-full max-w-3xl sm:text-xl sm:mb-5"
                dangerouslySetInnerHTML={{ __html: subtitle || '' }}
              />
            </Title>
          </section>
          {featuredItem && (
            <section>
              <div className="p-2 -mx-2 mb-5 border-brand-gray-2 hover:border-white focus:border-white hover:border-1 focus:border-1 hover:ring-2 focus:ring-2 hover:ring-blue-300 focus:ring-blue-300 hover:rounded-md focus:rounded-md xl:p-6 xl:-mx-6 xl:my-6 sm:gap-0">
                <Link
                  to={`/${featuredItem.slug}`}
                  className="flex flex-col gap-4 pb-4 bg-brand-blue-5 rounded-md overflow-hidden isolate sm:flex-row sm:bg-transparent sm:pb-0"
                >
                  <GatsbyImage
                    image={
                      featuredItem.frontmatter.image
                        ? getImage(featuredItem.frontmatter.image.childImageSharp)
                        : opengraph
                        ? opengraph
                        : ''
                    }
                    className="w-full object-contain shadow-lg max-h-72 rounded-none overflow-hidden isolate mb-4 mr-0 sm:w-1/2 sm:mr-12 sm:mb-0 sm:rounded-md"
                    loading="eager"
                    alt={featuredItem.frontmatter.image_alt || 'listing_opengraph'}
                  />
                  <div className="flex flex-col my-auto w-full px-4 gap-2 sm:w-1/2 sm:px-0">
                    <span className="text-xs font-bold uppercase text-white sm:text-brand-blue-4">
                      Featured
                    </span>
                    <h3 className="text-left text-base font-bold capitalize text-brand-green-3 pt-2 sm:text-lg sm:text-brand-blue-5 md:text-2xl lg:text-3xl sm:font-black sm:pt-0">
                      {featuredItem.frontmatter.title}
                    </h3>
                    <span className="text-left text-xs italic font-light text-brand-blue-1 sm:text-gray-700">
                      {featuredItem.frontmatter.date_modified}
                    </span>
                    <span className="text-sm font-light text-brand-blue-1 sm:text-gray-700 sm:text-lg">
                      {featuredItem.summary}
                    </span>
                  </div>
                </Link>
              </div>
            </section>
          )}
        </div>
        <div className="w-full bg-brand-blue-1">
          <section>
            <div ref={paginationRef} className="py-4 sm:py-12">
              <div className="flex flex-col gap-2 pb-4 items-center relative max-w-7xl mx-auto px-5 sm:pb-6 sm:gap-0 xl:px-8">
                {data?.allMdx?.nodes?.map((node, index) => (
                  <Link
                    key={index}
                    to={`/${node.slug}`}
                    className="flex flex-col gap-4 px-2 py-4 -mx-2 ring-offset-2 overflow-hidden isolate border-b border-brand-gray-2 hover:border-white focus:border-white hover:border-1 focus:border-1 hover:ring-2 focus:ring-2 hover:ring-blue-300 focus:ring-blue-300 hover:rounded-md focus:rounded-md sm:flex-row xl:px-6 xl:py-6 xl:-mx-6 sm:gap-12"
                  >
                    <GatsbyImage
                      className="w-full object-contain max-h-40 shadow-lg overflow-hidden isolate rounded-md sm:max-w-[247px] sm:max-h-36"
                      image={
                        node.frontmatter.image
                          ? getImage(node.frontmatter.image.childImageSharp)
                          : opengraph
                          ? opengraph
                          : ''
                      }
                      loading="lazy"
                      alt={node.frontmatter.image_alt || 'listing_opengraph'}
                    />
                    <div className="flex flex-col my-auto gap-1.5">
                      <h2 className="text-left text-base font-bold capitalize sm:text-base text-brand-blue-5 md:text-lg lg:text-xl sm:font-black">
                        {node.frontmatter.title}
                      </h2>
                      <span className="text-left text-xs italic font-light text-gray-700">
                        {node.frontmatter.date_modified}
                      </span>
                      <span className="line-clamp-4 text-sm font-light text-gray-700 sm:text-lg sm:line-clamp-2">
                        {node.summary}
                      </span>
                    </div>
                  </Link>
                ))}
              </div>
              <Pagination currentPage={currentPage} numPages={numPages} prefix={prefix} />
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query (
    $skip: Int!
    $limit: Int!
    $paths: [String!]!
    $listingType: String!
    $opengraph: String!
  ) {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: { post_type: { eq: $listingType }, hide: { ne: true } }
        slug: { in: $paths }
      }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        frontmatter {
          date_modified
          title
          election_name
          image: featured_image {
            childImageSharp {
              gatsbyImageData
            }
          }
          image_alt: featured_image_alt
        }
        id
        slug
        summary: excerpt(pruneLength: 200)
      }
    }
    opengraph: file(relativePath: { eq: $opengraph }) {
      id
      childImageSharp {
        gatsbyImageData
        resize(width: 1200) {
          src
          height
          width
        }
      }
    }
  }
`;

export default ListingTemplate;
